.wrapper__nav {
  box-shadow: -6px -1px 6px 0px rgba(0, 0, 0, 0.21);
  padding: 10px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  font-size: .75rem;

  &__logo {
    flex: 1 1 auto;
    max-width: 8%;

    img {
      max-height: 40px;
    }
  }

  &__section{
    flex: 5 1 auto;
    display: flex;
    
    ul {
      flex-direction: row;
      display: flex;
      list-style: none;
      padding-left: 20px;
      margin: 0;
    }

    li{
      padding: 0 10px;
      position: relative;
      align-self: center;
    }

    a{
      text-decoration: none;

      label{
        cursor: pointer;
      }
    }

    &--second {
      flex: 3 1 auto;
      justify-content: flex-end;
      padding-right: 20px;
    }

    &__logout {
      border: none;
      cursor: pointer;
      font-size: .75rem;
    }

    &__profile {
      display: flex;
      flex-direction: row;

      &__name {
        align-self: center;
        margin-left: 5px;
      }
    }
  }
}